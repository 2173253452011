
import { Options, mixins } from "vue-class-component";
import { CargoController } from "@/app/ui/controllers/CargoController";
import {
  AddBagOrStt,
  FlightPlanForECargo
} from "@/data/payload/api/CargoApiRequest";
import {
  commaDecimalToDecimal,
  dateTimePayload,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { CargoDetailStt, CargoPlaneAutoData } from "@/domain/entities/Cargo";
import { capitalize } from "@vue/shared";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import Print from "@/app/ui/views/cargo/modules/print.vue";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import { GTMBookingCargoEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/booking-cargo-event";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { flags } from "@/feature-flags";
/* eslint-disable @typescript-eslint/camelcase */

@Options({
  emits: ["leave-page", "onCheckCutOffEditRTC"],
  components: {
    Print
  }
})
export default class CargoProcessCargo extends mixins(OutGoingMixins) {
  mounted() {
    CargoController.setOpenSuccess(false);
    CargoController.setFailed(false);
    CargoController.setResetErrorInputStepThree();
  }

  get formBooking() {
    return CargoController.form;
  }

  get isFormBookingValid() {
    const cargoNumber =
      this.formBooking.cargoTypeStatus !== "auto" &&
      this.formBooking.cargoType !== "truck"
        ? this.formBooking.cargoNumber
        : true;
    const vehicleNumber =
      this.formBooking.cargoTypeStatus !== "auto"
        ? this.formBooking.vehicleNumber
        : true;
    return (
      this.formBooking.estDepartureDate &&
      (this.isPlaneAuto
        ? this.formBooking.airportOrigin.airportCode 
        &&
          (this.formBooking.airportDestination.airportCode || this.formBooking.destinationCityCode)
        : true) &&
      this.formBooking.cargoProduct &&
      this.formBooking.actualDimension &&
      this.formBooking.actualTotalCargoGrossWeight &&
      this.formBooking.actualTotalCargoVolumeWeight &&
      cargoNumber &&
      vehicleNumber && !this.errorInputStepThree &&
      !this.isErrorDiffPiecesActualThanDimensionPieces
    );
  }

  get errorInputStepThree() {
    return CargoController.errorInputStepThree;
  }

  get isErrorDiffPiecesActualThanDimensionPieces() {
    if (this.showButtonDimension) {
      return false;
    }
    return CargoController.isErrorDiffPiecesActualThanDimensionPieces;
  }
  get showButtonDimension() {
    return flags.feature_hide_dimension_koli.isEnabled();
  }

  get partnerName() {
    return capitalize(this.dataProfile.account_type_detail.company_name);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get typeUser() {
    return this.dataProfile.normalizedTypeUser;
  }

  get detailDistrict() {
    return LocationController.districtDetail;
  }

  get isPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get totalStt() {
    return this.sttResult.length;
  }

  get totalPieces() {
    return CargoController.totalSttPieces;
  }

  openConfirmationSave = false;

  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }

  onCloseSuccess() {
    this.setOpenSuccess(false);
    CargoController.setProgressValue(-1);
    const today = { hours: formatDate(new Date())?.split(",")[1]?.split(":")[0]?.trim(), minutes: formatDate(new Date())?.split(",")[1]?.split(":")[1]?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, '')?.trim()}
    this.isAllowed = ((+this.cutOffTime.hours < +today.hours) || (+this.cutOffTime.hours === +today.hours && +this.cutOffTime.minutes < +today.minutes));
    if (this.isEditCutoff && this.isAllowed) {
      CargoController.setIsFromCutOff(true);
      this.$router.push('/cargo');
    } else {
      this.$emit("leave-page", "/cargo");
    }
  }

  convertProduct(name: string) {
    return name.toLowerCase().includes("express") ? "Express" : "REGULAR";
  }

  get bagResult(): { baggingNumber: string; stt: CargoDetailStt[] }[] {
    return CargoController.bagResult;
  }

  get sttResult(): CargoDetailStt[] {
    return CargoController.sttResultFinal;
  }

  togglePanel(event: any) {
   if (this.formBooking.cargoType === "plane" && this.formBooking.actualTotalSTTPieces > 15) this.showPopupMaxKoliPlane();
    else if (this.isFormBookingValid) {
      const refs: any = this.$refs;
      refs?.op?.toggle(event);
    }
  }

  showPopupMaxKoliPlane() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: this.$t("Maksimal Koli Pesawat adalah 15 koli"),
        image: "max-koli-plane",
        message: this.$t(
          "Mohon ubah jumlah koli di dalam kargo atau pilih transportasi kargo lainnya"
        ),
        textCancel: this.$t("Transportasi Lain"),
        textSuccess: this.$t("Ubah Koli"),
        onClose: () => MainAppController.closeMessageModal(),
        onSubmit: () => MainAppController.closeMessageModal()
      })
    );
  }

  get iconTogglePanel() {
    const refs: any = this.$refs;
    return refs?.op?.visible ? "chevron-up-white" : "chevron-down-white";
  }

  isAllowed: any = false;

  createAndPrint(withPrint: boolean, label = false) {
    const refs: any = this.$refs;
    refs.op.hide();
    CargoController.setWithPrintCutOff(false);
    CargoController.setWithPrintLabelCutOff(false);
    this.withPrint = withPrint;
    this.withPrintLabel = label;
    const today = { hours: formatDate(new Date())?.split(",")[1]?.split(":")[0]?.trim(), minutes: formatDate(new Date())?.split(",")[1]?.split(":")[1]?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, '')?.trim()}
    this.isAllowed = ((+this.cutOffTime.hours < +today.hours) || (+this.cutOffTime.hours === +today.hours && +this.cutOffTime.minutes < +today.minutes)) && CargoController.progressValue !== - 1;
    if (this.isEditCutoff && this.isAllowed) {
      CargoController.setWithPrintCutOff(withPrint);
      CargoController.setWithPrintLabelCutOff(label);
      this.$emit('onCheckCutOffEditRTC');
      return;
    }
    this.handleConfirmationSave(true);
  }

  get isEditCutoff(){
    return this.isAllowEditCutoff;
  } 

  get isAllowEditCutoff(){
    const isEditRtcCutoff = ReadyToCargoController.flowBooking === 'rtc-edit' && this.isPlaneAuto && this.cutOffTime.hours !== undefined && this.cutOffTime.minutes !== undefined
    return isEditRtcCutoff;
  } 

  get cutOffTime() {
    return {hours: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[0], minutes: ReadyToCargoController.readyToCargoDetail.cutOffTime?.split(":")[1] };
  }

  withPrint = false;
  withPrintLabel = false;
  countSuccess = 0;
  countFailed = 0;

  get rcId(): number {
    return Number(this.$route.query.rcId);
  }

  // origin city
  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }
  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }

  get isRtc() {
    return (
      ReadyToCargoController.flowBooking === "rtc-process" ||
      ReadyToCargoController.flowBooking === "rtc-edit"
    );
  }

  get withPrintCutOff() {
    return CargoController.withPrintCutOff;
  }

  get withPrintCutOffLabel() {
    return CargoController.withPrintCutOffLabel;
  }

 showPopupTimeout(resp: any) {
    if (resp.bookingRequestId) { //success RTC Ngen
      this.showModalMessageBookingOnProcess(
        `Booking Kargo ${resp.bookingRequestId} <br/> Sedang Diproses`
      );
      this.bookingCargoGTM(this.eventName, "success");
    } else { //timeout RTC Ngen
      //timeout RTC Ngen
      if (resp.cargoNo) {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Booking Kargo Gagal Diproses nGen",
            message:
              "Lihat alasannya di halaman gagal booking. Cek & booking ulang",
            textSuccess: "OK",
            image: "image-modal-failed",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.$emit("leave-page", "/cargo/booking-canceled");
            }
          })
        );
      } else {
        if (resp.totalSttFailed && !resp.totalSttSuccess) {
         this.countFailed = resp.totalSttFailed;
        this.setFailed(true);
        CargoController.setSttFailed(resp.listSttFailed);

        // GTM
        this.bookingCargoGTM(this.eventName, "failed");
        const gtmBookingCargoFailed = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto
        }).bookingCargoFailed;

        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        this.isPartial = true;
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
      }
    }
    }
    MainAppController.closeLoading();
  }

  async onSave(): Promise<void> {
    const payloadBagNoOnly = JSON.parse(
      localStorage.getItem("cargo-bag") || "[]"
    )
      .map((item: any) =>
        item.isFromRebooking ? null : new AddBagOrStt(item.baggingNumber)
      )
      .filter((items: any) => items != null);

    const payloadSttNoOnly = JSON.parse(localStorage.getItem("cargo") || "[]")
      .filter(
        (item: any) =>
          !JSON.parse(
            localStorage.getItem("cargo-bag") || "[]"
          ).find((bag: any) => JSON.stringify(bag).includes(item.sttNumber)) ||
          JSON.parse(localStorage.getItem("cargo-bag") || "[]").find(
            (itemBag: any) => item.isFromRebooking == itemBag.isFromRebooking // add validation to check on cargo-bag that come from rebooking
          )
      )
      .map((item: any) => {
        if (item.sttDetails) {
          return item.sttDetails.map(
            (item: any) => new AddBagOrStt(undefined, item.sttNumber)
          );
        } else {
          return new AddBagOrStt(undefined, item.sttNumber);
        }
      })
      .flat();
    this.handleConfirmationSave(false);
    if (
      this.formBooking.cargoType === "plane" &&
      this.calculateFlightDate.length &&
      this.flowBooking === "rtc-process"
    ) {
      this.formBooking.airportOrigin.value = this.calculateFlightDate[0]?.flight_origin;
      this.formBooking.airportDestination.value = this.calculateFlightDate[
        this.calculateFlightDate.length - 1
      ]?.flight_destination;
    }
    CargoController.setTimestampBooking(new Date().getTime());
    const resp = await CargoController.onCreateCargo({
      originAirportCode:
        this.formBooking.airportOrigin?.value ||
        LocationController.districtDetail.cityCode,
      destinationAirportCode:
        this.formBooking.airportDestination.value ||
        this.formBooking.destinationCityCode,
      estTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.estTotalCargoGrossWeight
      ),
      estTotalCargoVolumeweight: commaDecimalToDecimal(
        this.formBooking.estTotalCargoVolumeWeight
      ),
      partnerName: this.partnerName,
      partnerType: this.typeUser,
      cargoNo: this.cargoPlaneAutoData.cargoNumber,
      cargoVehicleNo: this.cargoPlaneAutoData.vehicleNumber,
      actualTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.actualTotalCargoGrossWeight
      ),
      actualTotalCargoVolumeweight: commaDecimalToDecimal(
        this.formBooking.actualTotalCargoVolumeWeight
      ),
      route: this.cargoPlaneAutoData.route, // from nGen
      totalStt: this.totalStt,
      totalPiece: this.totalPieces,
      cargoCommodity: this.formBooking.cargoCommodityCode,
      productCargo: this.convertProduct(this.formBooking.cargoProduct),
      cargoType: this.formBooking.cargoType,
      originCityCode: this.detailDistrict.cityCode,
      destinationCityCode: this.formBooking.destinationCityCode,
      departureDate: dateTimePayload(this.cargoPlaneAutoData.estDepartureDate),
      arrivalDate: dateTimePayload(this.cargoPlaneAutoData.estArrivalDate),
      bagOrStt: [...payloadSttNoOnly, ...payloadBagNoOnly],
      isCargoPlaneManual:
        this.formBooking.cargoType === "plane" &&
        this.formBooking.cargoTypeStatus === "manual",
      cargoActualPiece: +this.formBooking.actualTotalSTTPieces,
      flightPlanForECargo: this.cargoPlaneAutoData.flightData,
      dimensions: this.mappingDetailPieces,
      estDimension: this.formBooking.estDimension,
      actualDimension: this.formBooking.actualDimension,
      isFromRtc: this.isRtc || this.dataDetailRetryCargo.isFromRtc || this.isFromRebookingRTC,
      rtcId: this.isRtc ? ReadyToCargoController.readyToCargoDetail.id : 0,
      nog: this.formBooking.nog,
      rebookingId: this.rcId,
      ngenCreatedAt: dateTimePayload(this.formBooking.ngenCreatedAt),
      shc: this.formBooking.shcCode
    }).finally(() => {
      MainAppController.closeMessageModal();
    });
    this.onCreateResponse(resp);
    this.handleConfirmationSave(false);
  }
  get cargoPlaneAutoData(): CargoPlaneAutoData {
    const today = { hours: formatDate(new Date())?.split(",")[1]?.split(":")[0]?.trim(), minutes: formatDate(new Date())?.split(",")[1]?.split(":")[1]?.replace(/\bwib\b|\bwita\b|\bwit\b|\bgmt\b/gi, '')?.trim()}
    this.isAllowed = ((+this.cutOffTime.hours < +today.hours) || (+this.cutOffTime.hours === +today.hours && +this.cutOffTime.minutes < +today.minutes)) && CargoController.progressValue !== - 1;
    if (
      this.formBooking.cargoType === "plane" &&
      this.calculateFlightDate.length &&
      this.flowBooking === "rtc-process" &&
      !(!this.detail.cutOffTime && this.detail.cargoType === 'plane')
    ) {
      return new CargoPlaneAutoData({
        estDepartureDate: this.calculateFlightDate[0].flight_dep_datetime,
        estArrivalDate: this.calculateFlightDate[
          this.calculateFlightDate.length - 1
        ].flight_arr_datetime,
        flightData: this.calculateFlightDate
      });
    }
    if (this.isPlaneAuto) {
      const origin =
        this.formBooking.flightsData[0]?.Route.split("-")[0] ||
        this.originCity.code;
      const destination =
        this.formBooking.flightsData[
          this.formBooking.flightsData.length - 1
        ].Route.split("-")[1] || this.destinationCity.code;
      const route = `${origin}-${destination}`;
      return new CargoPlaneAutoData({
        route: !route.includes("undefined") ? route : "",
        estDepartureDate:
          this.formBooking.flightsData[0].DepartureDateTime ||
          this.formBooking.estDepartureDate,
        estArrivalDate: this.formBooking.flightsData[
          this.formBooking.flightsData.length - 1
        ].ArrivalDateTime,
        flightData: this.isRtc && !(!this.detail.cutOffTime && this.detail.cargoType === 'plane') && !this.isChangeCargoType && !(this.isEditCutoff && this.isAllowed) ? this.calculateFlightDate : this.flightData 
      });
    }
    return new CargoPlaneAutoData({
      estDepartureDate: this.formBooking.estDepartureDate,
      estArrivalDate: this.formBooking.estArrivalDate,
      cargoNumber: this.formBooking.cargoNumber,
      vehicleNumber: this.formBooking.vehicleNumber
    });
  }
  get flightData() {
    let data = this.formBooking.flightsData.map(
      item =>
        new FlightPlanForECargo( // from ngen
          !this.formBooking.airportOrigin.name &&
          !this.formBooking.airportDestination.name
            ? ""
            : `${item.DailyFlightSNo}`,
          true, // isRootStation
          item.FlightNo,
          item.DepartureDateTime
            ? this.$moment(item.DepartureDateTime.split(" ")[0]).format(
                "YYYY-MM-DD"
              )
            : "",
          item.Route.split("-")[0] || item.airportCodeOrigin,
          item.Route.split("-")[1] || item.airportCodeDestination,
          item.DepartureDateTime
            ? this.$moment(item.DepartureDateTime).format("YYYY-MM-DD HH:mm:ss")
            : "",
          item.ArrivalDateTime
            ? this.$moment(item.ArrivalDateTime).format("YYYY-MM-DD HH:mm:ss")
            : "",
          item.DepartureDateTime
            ? this.$moment(item.DepartureDateTime).format("YYYY-MM-DD HH:mm:ss")
            : "",
        )
    );
    if(this.isRtc || (this.flowBooking === "rebooking" && this.$route.query.rcId && this.isFromRebookingRTC)) {
      data = data.map((item: any) => {
        return {
          ...item,
          flight_threshold_time: +this.formBooking.thresholdTime
        }
      })
    }
    return data;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  // failed for create
  isPartial = false;
  get timestampBooking() {
    return CargoController.timestampBooking;
  }

  get isChangeCargoType() {
    return CargoController.isChangeCargoType
  }
  get isFailed(): boolean {
    return CargoController.isFailed;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get detail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get calculateFlightDate() {
    return ReadyToCargoController.calculateFlightDate.map((key)=> {
      return {...key, flight_threshold_time : this.detail.isMoreThanCutOffTime ? +this.formBooking.thresholdTime : key.flight_threshold_time }
    });
  }

  setFailed(value: boolean) {
    CargoController.setFailed(value);
  }

  showModalMessageBookingOnProcess(title: string) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title,
        message: "Anda akan mendapat notifikasi jika booking kargo berhasil",
        textSuccess: "OK",
        image: "illustration_waiting_process",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onCloseSuccess();
        },
      })
    );
  }


  onCreateResponse(resp: any) {
    if (resp) {
      if (this.formBooking.cargoType === "plane" && this.formBooking.cargoTypeStatus === "auto" && (this.isRtc || (this.flowBooking === "rebooking" && this.$route.query.rcId && this.isFromRebookingRTC)) && flags.feature_is_booking_cargo_v4.isEnabled()) {
        this.showPopupTimeout(resp);
        return;
      }
        else if (resp.cargoNo.startsWith("FLIGHT") || resp.isCargoUsingAwb) { //error Ngen Non RTC
        this.showModalMessageBookingOnProcess("Booking Kargo Sedang Diproses");
        this.bookingCargoGTM(this.eventName, "success");
      } else if (resp.totalSttFailed && !resp.totalSttSuccess) {
         this.countFailed = resp.totalSttFailed;
        this.setFailed(true);
        CargoController.setSttFailed(resp.listSttFailed);

        // GTM
        this.bookingCargoGTM(this.eventName, "failed");
        const gtmBookingCargoFailed = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto
        }).bookingCargoFailed;

        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        this.isPartial = true;
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
      } else {
        // GTM
        this.bookingCargoGTM(this.eventName, "success");
        const gtmBookingCargoSuccess = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto,
          gtmData: {
            cargoNumber: CargoController.cargoIdToGeneratePdf,
            flightNumber: this.formBooking.vehicleNumber
          }
        }).bookingCargoSuccess;

        dataLayer(
          gtmBookingCargoSuccess.eventName,
          gtmBookingCargoSuccess.property,
          ["timestamp"]
        );

        CargoController.setOpenSuccess(true);
        this.onPrint();
      }
    }
  }

  bookingCargoGTM(eventName: any, result: string) {
    GTMBookingCargoEvent(eventName, {
      cargo_type: this.formBooking.cargoType,
      "3LC_origin": this.detailDistrict.cityCode,
      "3LC_destination": this.formBooking.destinationCityCode,
      booking_result: result,
      cargo_number: CargoController.cargoIdToGeneratePdf,
      timestamp_booking: this.timestampBooking,
      timestamp_get_result: new Date().getTime()
    });
  }

  get eventName() {
    return ReadyToCargoController.eventName;
  }

  onPrint() {
    const refs: any = this.$refs;
    if (this.withPrint || this.withPrintCutOff) {
      refs.print.printManifestV2(CargoController.cargoIdToGeneratePdf);

      // GTM
      const gtmBookingCargoSuccessPDF = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessPDF;

      dataLayer(
        gtmBookingCargoSuccessPDF.eventName,
        gtmBookingCargoSuccessPDF.property,
        ["timestamp"]
      );
    }
    if (this.withPrintLabel || this.withPrintCutOffLabel) {
      refs.print.printData(CargoController.cargoIdToGeneratePdf, "label");

      // GTM
      const gtmBookingCargoSuccessLabel = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessLabel;

      dataLayer(
        gtmBookingCargoSuccessLabel.eventName,
        gtmBookingCargoSuccessLabel.property,
        ["timestamp"]
      );
    }
  }

  get openSuccess(): boolean {
    return CargoController.isOpenSuccess;
  }

  setOpenSuccess(value: boolean) {
    CargoController.setOpenSuccess(value);
  }

  get isShowPopup(): boolean {
    return (
      this.openConfirmationSave ||
      this.openSuccess ||
      this.isPartial ||
      this.isFailed
    );
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  get popupModalData() {
    // confirmation save
    if (this.openConfirmationSave) {
      return {
        success: {
          action: this.onSave,
          text: this.isAccountForeign ? "Create" : "Process"
        },
        cancel: {
          action: this.handleConfirmationSave,
          text: this.$t("Batal")
        },
        title: this.$t("cargo.confirmCreateCargoTitle"),
        message: this.$t("Pastikan kembali, seluruh detail STT sudah benar dan sesuai."),
        image: "are-you-sure"
      };
    }
    // if success
    if (this.openSuccess) {
      return {
        success: {
          action: this.onCloseSuccess,
          text: "OK"
        },
        title: this.$t("Pembuatan Berhasil !"),
        message: this.$t("cargo.successCreateMessage", {cargoNo: CargoController.cargoIdToGeneratePdf}),
        image: "image-modal-success"
      };
    }

    // if success partially
    if (this.isPartial) {
      return {
        success: {
          action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
          text: "Download"
        },
        cancel: {
          action: this.onCloseSuccess,
          text: "OK"
        },

        title: this.$t("Pembuatan Berhasil !"),
        message: this.$t("cargo.partialCreateMessage", {cargoNo: CargoController.cargoIdToGeneratePdf}),
        image: "image-modal-warning"
      };
    }

    // default is failed
    return {
      success: {
        action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        text: "Download"
      },
      cancel: {
        action: () => this.setFailed(false),
        text: "OK"
      },
      title: this.$t("Pembuatan Gagal!"),
      message: this.$t("Pengiriman kargo gagal dibuat."),
      image: "image-modal-failed"
    };
  }

  get sttFailedUpdate() {
    return CargoController.sttFailed;
  }

  get dataDetailRetryCargo() {
    return CargoController.cargoDetailData;
  }

  setPartial(value: boolean) {
    this.isPartial = value;
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "cargo_stt_failed.csv",
      listStt: data
    });
  }

  get formDetailPieces() {
    return CargoController.formDetailPieces;
  }

  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  get mappingDetailPieces() {
    return this.formDetailPieces.dimensionPieces.map((item: any) => {
      return {
        pieces: !this.isHaveBeenSubmitDetailPieces
          ? +this.formBooking.actualTotalSTTPieces
          : +item.pieces,
        length: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.length
          : item.length,
        width: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.width
          : item.width,
        height: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.height
          : item.height
      };
    });
  }
}
